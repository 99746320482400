import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

function RedirectToExternal({ url }) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
}

const address1 = "https://t.me/+VVcd4lnStpc5Yzc0";
const address2 = "https://t.me/GDETT443QFAbot";

function App() {
  return (
    <Router>
      <Routes>
     	<Route path="e/*" element={<RedirectToExternal url={address1} />} />
      	<Route path="*" element={<RedirectToExternal url={address2} />} />
      </Routes>
    </Router>
  );
}

export default App;
